import { css } from '@emotion/react';
import { ChevronRight } from '../assets/icons';

const beltBannerStyle = css`
  background: #ffe45a;
  padding: var(--medium) var(--large);
  display: flex;
  justify-content: space-between;

  font-size: 1.75rem;
  font-weight: 500;
  line-height: var(--xlarge);
`;

interface BeltBannerProps {
  appId: string;
}

export default function BeltBanner({ appId }: BeltBannerProps) {
  return (
    <a
      css={beltBannerStyle}
      href="https://studio.realworld.to/"
      onClick={() => {
        gtag('event', 'click_link_toStudio', {
          userID: window.Realworld.getUserId(),
          creatorName_creatorID: appId,
        });
      }}
    >
      <span
        css={css`
          flex: 1;
        `}
      >
        ❤️ ️스튜디오에서 직접 리얼월드 게임을 만들어보세요!
      </span>
      ️
      <ChevronRight width="2rem" />
    </a>
  );
}
