import React from 'react';
import styled from '@emotion/styled';
import ContentsSection from '../Contents/ContentsSection';

interface ContentType extends React.ComponentPropsWithoutRef<'div'> {
  contents: string;
  creator: Creator;
  creatorFollowData?: {
    isFollowed: boolean;
  };
}

const Tab = ({
  creator,
  contents,
  creatorFollowData,
  ...containerProps
}: ContentType) => {
  return (
    <Container {...containerProps}>
      <ContentsSection
        content={contents}
        creator={creator}
        creatorFollowData={creatorFollowData}
      />
    </Container>
  );
};

export { Tab };

const Container = styled.div`
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
`;
