import React from 'react';
import FeedItem from './FeedItem';
import { css } from '@emotion/react';
import Divider from '../Divider';
import { FeedType } from '../../models/feedType';
import PinItem from '../Home/PinItem';

interface ProfileProps {
  creator: Creator;
  feedList: Content[];
  feedType: FeedType;
  myId?: string;
  setLastRef: (element?: HTMLElement | null | undefined) => void;
  openAlertModal: (text: string) => unknown;
  creatorFollowData: { isFollowed: boolean };
}

export interface Content {
  id: string;
  content: string;
  writer: Writer;
  channelId: string;
  images: Image[];
  commentCount: number;
  likeCount: number;
  isLiked: boolean;
  isCreator: boolean;
  isPinned: boolean;
  createdAt: string;
}

export interface Writer {
  id: string;
  name: string;
  profileUrl: string;
  channelType: string;
}

export interface Image {
  id?: string;
  url?: string;
}

const FeedItemList = ({
  creator,
  feedList,
  feedType,
  myId,
  setLastRef,
  openAlertModal,
  creatorFollowData,
}: ProfileProps) => {
  return (
    <>
      {feedList.map((item, idx: number) => (
        <div
          css={SectionContents}
          key={item.id}
          ref={idx === feedList.length - 1 ? setLastRef : undefined}
        >
          <FeedItem
            feedData={item}
            feedType={feedType}
            myId={myId}
            openAlertModal={openAlertModal}
            creatorFollowData={creatorFollowData}
          />
          <Divider />
        </div>
      ))}
    </>
  );
};

export default FeedItemList;

const SectionContents = css`
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
`;
