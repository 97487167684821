import { css } from '@emotion/react';
import React from 'react';
import { ImageEmptystateSearch } from '../assets/icons';

export default function EmptyFeedList() {
  return (
    <div css={emptyFeedWarp}>
      <ImageEmptystateSearch />
      <h1>아직 등록된 게시물이 없어요 😢</h1>
    </div>
  );
}

const emptyFeedWarp = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 46px;
  padding-bottom: 46px;

  > h1 {
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 26px;
    margin-top: 24px;
  }
`;
