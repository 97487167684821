import { css } from '@emotion/react';
import FeedItemList from '../Feed/FeedItemList';
import HomeContent from '../Home/HomeContent';
import EmptyFeedList from '../EmptyFeedList';
import Divider from '../Divider';
import EmptyPinItem from '../EmptyPinItem';
import LoadingSpinner from '../LoadingSpinner';
import { FeedType } from '../../models/feedType';

import useSWR from 'swr';
import { getLocalStorage } from '../../utils/localStorage';
import { LocalStorageKey } from '../../models/localStorage';
import { swrDefaultOption } from '../../utils/swr';
import { Link } from 'gatsby';
import { getHrefUrl } from '../../utils/route';
import { useLocation } from '@reach/router';
import axios from 'axios';
import { useCoreApi } from '../../apis/core';
import { CORE_SERVER_HOST } from '../../configs';
import useSWRInfinite from 'swr/infinite';
import qs from 'qs';
import useIntersect from '../../utils/useIntersect';
import React from 'react';
import { useInView } from 'react-cool-inview';
import Modal from '../Modal/Modal';
import ModalCheck from '../Modal/ModalCheck';
import { ProfileImage } from '../Profile/ProfileImage';

type ContentProps = {
  content: string;
  creator: Creator;
  creatorFollowData?: {
    isFollowed: boolean;
  };
};
const ContentSection = ({
  content,
  creator,
  creatorFollowData,
}: ContentProps) => {
  const location = useLocation();
  const coreApi = useCoreApi();
  const accessToken = getLocalStorage(LocalStorageKey.ACCESS_TOKEN);
  const feedTotalPages = React.useRef(0);
  const userFeedTotalPages = React.useRef(0);
  const [isOpenAlertModal, setIsOpenAlertModal] = React.useState(false);
  const [alertModalText, setAlertModalText] = React.useState('');

  const openAlertModal = React.useCallback(
    (text: string) => {
      setAlertModalText(text);
      setIsOpenAlertModal(true);
    },
    [setIsOpenAlertModal]
  );

  const handleCloseAlertModal = React.useCallback(() => {
    setIsOpenAlertModal(false);
  }, [setIsOpenAlertModal]);

  const fetcherAPI = async (url: string) => {
    const { data } = await axios.get(`https://${CORE_SERVER_HOST}${url}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  };

  /** TODO : 배포 전에 const channelId = creator.id; 로 변경 */
  const channelId = creator.id;

  const getFeedsKey = (
    pageIndex: number,
    previousPageData?: { totalPage: number }
  ) => {
    if (previousPageData && previousPageData.totalPage < pageIndex + 1)
      return null;
    return `/api/${channelId}/community/creatorFeeds?${qs.stringify({
      take: 10,
      page: pageIndex + 1,
    })}`;
  };

  /** 크리에이터 피드 데이터 */
  const {
    data: feedsData,
    error: feedsError,
    isLoading: isLoadingFeeds,
    size: feedsSize,
    setSize: setFeedsSize,
    mutate: mutateFeeds,
  } = useSWRInfinite(getFeedsKey, fetcherAPI, {
    ...swrDefaultOption,
    revalidateFirstPage: false,
  });

  useSWR(
    ['mutateContentSectionFeeds', channelId, FeedType.CREATOR],
    () => {
      if (isLoadingFeeds) return;
      mutateFeeds();
    },
    { ...swrDefaultOption, revalidateOnFocus: false, revalidateOnMount: false }
  );

  React.useEffect(() => {
    if (!feedsData) return;
    feedTotalPages.current = feedsData[feedsData.length - 1].totalPage;
  }, [feedsData]);

  const { observe: observeFeed } = useInView({
    rootMargin: '50px 0px',
    onEnter: ({ unobserve }) => {
      unobserve();
      if (feedTotalPages.current < feedsSize + 1) return;

      setUserFeedsSize(feedsSize + 1);
    },
  });

  const getUserFeedsKey = (
    pageIndex: number,
    previousPageData?: { totalPage: number }
  ) => {
    if (previousPageData && previousPageData.totalPage < pageIndex + 1)
      return null;
    return `/api/${channelId}/community/UserFeeds?${qs.stringify({
      take: 10,
      page: pageIndex + 1,
    })}`;
  };

  /** 유저 피드 데이터 */
  const {
    data: userFeedsData,
    error: userFeedsError,
    isLoading: isLoadingUserFeeds,
    size: userFeedsSize,
    setSize: setUserFeedsSize,
    mutate: mutateUserFeeds,
  } = useSWRInfinite(getUserFeedsKey, fetcherAPI, {
    ...swrDefaultOption,
    revalidateFirstPage: false,
  });

  useSWR(
    ['mutateContentSectionFeeds', channelId, FeedType.USER],
    () => {
      if (isLoadingUserFeeds) return;
      mutateUserFeeds();
    },
    { ...swrDefaultOption, revalidateOnFocus: false, revalidateOnMount: false }
  );

  React.useEffect(() => {
    if (!userFeedsData) return;
    userFeedTotalPages.current =
      userFeedsData[userFeedsData.length - 1].totalPage;
  }, [userFeedsData]);

  const { observe: observeUserFeed } = useInView({
    rootMargin: '50px 0px',
    onEnter: ({ unobserve }) => {
      unobserve();
      if (userFeedTotalPages.current < userFeedsSize + 1) return;

      setUserFeedsSize(userFeedsSize + 1);
    },
  });

  async function sendGetMeRequest() {
    return coreApi.getMe({
      accessToken: accessToken || '',
    });
  }

  /** 내 정보 데이터 */
  const { data: getMeData, error: getMeError } = useSWR(
    ['getMe', creator.id],
    sendGetMeRequest,
    swrDefaultOption
  );

  /** 유저 피드 데이터 */
  const {
    data: pinFeedData,
    error: pinFeedError,
    isLoading: isLoadingPinFeed,
    mutate: mutatePinFeed,
  } = useSWR(
    `/api/${channelId}/community/creatorFeeds?${qs.stringify({ isPin: true })}`,
    fetcherAPI
  );

  // route 변경시 refetch
  React.useEffect(() => {
    if (!isLoadingFeeds) mutateFeeds();
    if (!isLoadingUserFeeds) mutateUserFeeds();
    if (!isLoadingPinFeed) mutatePinFeed();
  }, [location]);

  // TODO : 배포 전에 수정하기
  if (feedsError || userFeedsError || pinFeedError) return <></>;
  if (!feedsData || !userFeedsData || !pinFeedData || !creatorFollowData)
    return <LoadingSpinner />;

  const feedsDataLength = feedsData
    .map(({ contents }) => contents)
    .flat().length;
  const userFeedsDataLength = userFeedsData
    .map(({ contents }) => contents)
    .flat().length;

  if (content === 'home') {
    return (
      <section css={contentStyle}>
        {!pinFeedData.contents.length ? (
          <EmptyPinItem />
        ) : (
          <HomeContent
            creator={creator}
            pinItem={pinFeedData.contents[0]}
            feedType={FeedType.CREATOR}
            openAlertModal={openAlertModal}
          />
        )}

        <Divider />
      </section>
    );
  } else if (content === 'feed') {
    return (
      <section css={contentStyle}>
        {feedsDataLength > 0 ? (
          <FeedItemList
            creator={creator}
            feedList={feedsData.map(({ contents }) => contents).flat()}
            feedType={FeedType.CREATOR}
            setLastRef={observeFeed}
            openAlertModal={openAlertModal}
            creatorFollowData={creatorFollowData}
          />
        ) : (
          <EmptyFeedList />
        )}

        {isOpenAlertModal && (
          <Modal closeModal={handleCloseAlertModal}>
            <ModalCheck
              closeModal={handleCloseAlertModal}
              content={alertModalText}
            />
          </Modal>
        )}
      </section>
    );
  } else {
    return (
      <section css={contentStyle}>
        <div
          css={css`
            overflow-x: hidden;
          `}
        >
          {creatorFollowData.isFollowed ? (
            <Link
              to={getHrefUrl({
                query: {
                  channelId,
                  feedType: FeedType.USER,
                },
                path: '/feedDetailAdd',
              })}
              state={{
                previousPathname: location.pathname,
              }}
              css={css`
                overflow-x: hidden;
              `}
            >
              <div css={textHelpWarpStyle}>
                <ProfileImage
                  profileUrl={getMeData?.profileImage}
                  isCreator={false}
                  css={profileImage}
                />
                <div css={textHelpStyle}>커뮤니티에 게시물을 남겨보세요!</div>
              </div>
            </Link>
          ) : (
            <div
              css={css`
                overflow-x: hidden;
              `}
              onClick={() => {
                openAlertModal(
                  '크리에이터를 팔로우해야 커뮤니티 게시물을 작성할 수 있어요!'
                );
              }}
            >
              <div css={textHelpWarpStyle}>
                <ProfileImage
                  profileUrl={getMeData?.profileImage}
                  isCreator={false}
                  css={profileImage}
                />
                <div css={textHelpStyle}>
                  크리에이터를 팔로우하고 메시지를 남겨주세요!
                </div>
              </div>
            </div>
          )}
          {userFeedsDataLength > 0 ? (
            <FeedItemList
              creator={creator}
              feedList={userFeedsData.map(({ contents }) => contents).flat()}
              feedType={FeedType.USER}
              myId={getMeData?.id}
              setLastRef={observeUserFeed}
              openAlertModal={openAlertModal}
              creatorFollowData={creatorFollowData}
            />
          ) : (
            <EmptyFeedList />
          )}
        </div>

        {isOpenAlertModal && (
          <Modal closeModal={handleCloseAlertModal}>
            <ModalCheck
              closeModal={handleCloseAlertModal}
              content={alertModalText}
            />
          </Modal>
        )}
      </section>
    );
  }
};

export default ContentSection;

const contentStyle = css`
  background-color: #f4f4f4;
`;

const profileImage = css`
  border: 1px solid #ededed;
  border-radius: 50%;
  width: 5.75rem;
  height: 5.75rem;
  object-fit: cover;
  margin-bottom: var(--small);
`;

const textHelpWarpStyle = css`
  width: 100%;
  height: 72px;
  display: flex;
  padding: 14px 18px;
  background-color: white;
  box-sizing: border-box;
  margin-bottom: 9px;
`;

const textHelpStyle = css`
  box-sizing: border-box;
  margin: 11px 10px;
  width: 80%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  overflow-y: hidden;
  color: #626262;
  opacity: 1;
`;
