import { css } from '@emotion/react';
import { FeedType } from '../../models/feedType';
import FeedItem from '../Feed/FeedItem';
import PinItem from './PinItem';

type ContentProps = {
  creator: Creator;
  pinItem: Content;
  feedType: FeedType;
  openAlertModal: (text: string) => unknown;
};

interface Content {
  id: string;
  content: string;
  isLiked: boolean;
  likeCount: number;
  channelId: string;
  images: Image[];
  commentCount: number;
  createdAt: string;
  upadtedAt: string;
  isCreator: boolean;
  writer: Writer;
  isPinned: boolean;
}

export interface Image {
  id: string;
  url: string;
}

export interface Writer {
  id: string;
  name: string;
  profileUrl: string;
  channelType: string;
}

const HomeContent = ({
  creator,
  pinItem,
  feedType,
  openAlertModal,
}: ContentProps) => {
  return (
    <div css={SectionContents}>
      <PinItem
        pinItem={pinItem}
        feedType={feedType}
        openAlertModal={openAlertModal}
      />
    </div>
  );
};

export default HomeContent;

const SectionContents = css`
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
`;
